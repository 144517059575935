<template>
  <div class="your-roof-guests mt-4 mt-lg-0">
    <h1 class="title">{{$t('Guests')}}</h1>
    <div class="guests-header" v-if="reservationGuests.length > 1">
      <div class="guest-icon"
      :class="{ selected: guest === selectedGuest }"
      v-for="(guest, index) in reservationGuests" :key="index" @click="selectGuest(index)">
        <FontAwesomeIcon :icon="['fas', 'user']"/>
      </div>
    </div>
    <ValidationObserver ref="validator">
      <form @submit.prevent="update" class="guest-info">
        <RFValidation rules="required" name="Firstname">
          <RFInput label="Firstname" v-model="guestInfos.firstname" :value="guestInfos.firstname" :readonly="isVerifiedStatus"/>
        </RFValidation>

        <RFValidation rules="required" name="Lastname">
          <RFInput label="Lastname" v-model="guestInfos.lastname" :value="guestInfos.lastname" :readonly="isVerifiedStatus"/>
        </RFValidation>

        <RFValidation rules="required" name="Birth Date">
          <RFDatePicker label="Birth Date" v-model="guestInfos.birthDate" :value="guestInfos.birthDate" :readonly="isVerifiedStatus"/>
        </RFValidation>

        <RFRadio class="ml-3" label="Gender" name="gender" v-model="guestInfos.genre" :list="[{ label: 'M', value: 'MALE' }, { label: 'F', value: 'FEMALE' }]" :disabled="isVerifiedStatus"/>

        <RFValidation rules="required" name="Nationality">
          <RFInput label="Nationality" v-model="guestInfos.nationality" :value="guestInfos.nationality" :readonly="isVerifiedStatus" v-if="isVerifiedStatus"/>
          <RFInputAutocomplete label="Nationality" v-model="guestInfos.nationality"  :items="nationalities"  v-else/>
        </RFValidation>

        <RFValidation rules="required|email" name="Email">
          <RFInput label="Email" v-model="guestInfos.email" :value="guestInfos.email" :readonly="isVerifiedStatus"/>
        </RFValidation>

        <RFValidation rules="required" name="Country Code">
          <RFInput label="Country Code" v-model="guestInfos.countryCode" :value="guestInfos.countryCode" :readonly="isVerifiedStatus" v-if="isVerifiedStatus"/>
          <RFInputAutocomplete label="Country Code" v-model="guestInfos.countryCode"  :items="countryCodes"  v-else/>
        </RFValidation>

        <RFValidation name="Whatsapp Contact" rules="required|phoneNumber">
          <RFInput label="Whatsapp contact" v-model="guestInfos.whatsappContact" :value="guestInfos.whatsappContact" is-numeric :readonly="isVerifiedStatus"/>
        </RFValidation>

        <RFRadio class="ml-3" label="What do you do?" name="employment" v-model="guestInfos.employment" :list="[{ label: 'Study', value: 'study'}, { label: 'Work', value: 'work' }]" :disabled="isVerifiedStatus"/>

        <RFRadio disabled class="ml-3" label="Do you have a University Letter?" name="uni-letter" v-model="guestInfos.uniLetter" :list="[{ label: 'Yes', value: 'yes'}, { label: 'No', value: 'no' }, { label: 'Not Yet', value: 'not_yet'}]"/>

        <div class="your-info-subtitle">{{$t('Complete your personal info')}}</div>

        <RFValidation name="Fiscal Code" rules="required|fiscalCode" v-if="isItalian">
          <RFInput label="Fiscal Code" v-model="guestInfos.fiscalCode" :value="guestInfos.fiscalCode" :readonly="isVerifiedStatus" uppercase/>
        </RFValidation>

        <RFValidation name="Fiscal Code" rules="fiscalCode" v-else>
          <RFInput label="Fiscal Code" v-model="guestInfos.fiscalCode" :value="guestInfos.fiscalCode" :readonly="isVerifiedStatus" uppercase/>
        </RFValidation>

        <RFValidation :name="isItalian ? 'ID Document #' : 'Passport #'" rules="required">
          <RFInput :label="isItalian ? 'ID Document #' : 'Passport #'" v-model="guestInfos.docNumber" :value="guestInfos.docNumber" :readonly="isVerifiedStatus" uppercase/>
        </RFValidation>

        <RFValidation rules="required" name="Place of birth">
          <RFInput label="Place of birth" v-model="guestInfos.placeOfBirth" :value="guestInfos.placeOfBirth" :readonly="isVerifiedStatus"/>
        </RFValidation>

        <RFValidation name="Country" rules="required">
          <RFInput label="Country" v-model="guestInfos.addressCountry" :value="guestInfos.addressCountry" :readonly="isVerifiedStatus" v-if="isVerifiedStatus"/>
          <RFInputAutocomplete label="Country" :value="guestInfos.addressCountry" :items="countries" v-model="guestInfos.addressCountry" v-else/>
        </RFValidation>

        <b-row>
          <b-col>
            <RFValidation name="City of residence" rules="required">
              <RFInput label="City of residence" v-model="guestInfos.addressCity" :value="guestInfos.addressCity" :readonly="isVerifiedStatus"/>
            </RFValidation>
          </b-col>
          <b-col cols="12" md="4">
            <RFInput :label="$t('Zip Code')" v-model="guestInfos.addressZipCode" :value="guestInfos.addressZipCode" is-numeric :readonly="isVerifiedStatus"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation name="Street of residence" rules="required">
              <RFInput label="Street of residence" v-model="guestInfos.addressStreet" :value="guestInfos.addressStreet" :readonly="isVerifiedStatus"/>
            </RFValidation>
          </b-col>
          <b-col cols="12" md="4">
            <RFInput label="Street number" v-model="guestInfos.addressNumber" :value="guestInfos.addressNumber" is-numeric :readonly="isVerifiedStatus"/>
          </b-col>
        </b-row>

        <RFButton class="mt-4" label="Save" background="#fff" color="#24425B" v-if="!isVerifiedStatus && !loading"/>
        <RFLoader class="mt-4" :size="30" color="#24425B" v-if="loading"/>
      </form>
    </ValidationObserver>
    <div class="complete-profile" v-if="isRegisteredStatus">
      {{$t('Please complete guest info and then upload his/her documents.',{guest : $t('guest')})}}
    </div>
    <form @submit.prevent class="guest-documents" v-else>
      <b-row>
        <b-col>
          <div class="documents-title">{{$t('Upload guest documents',{guest : $t('guest')})}}</div>
        </b-col>
      </b-row>
      <b-row class="upload-row">
        <b-col>
          <span class="upload-label" v-if="isEuropean">{{$t('ID Document')}}</span>
          <span class="upload-label" v-else>{{$t('Passport')}}</span>
        </b-col>
        <b-col cols="12" class="my-2" v-if="!isVerifiedStatus">
          <RFCheckbox color="#fff" :label="$t('Does your document have a front and back page (upload separately)?')" v-model="hasFrontBackDocument" :readonly="isVerifiedStatus"/>
        </b-col>
        <b-col cols="12" v-if="hasFrontBackDocument">
          <RFUploadDocument :docName="isEuropean ? $t('ID Document') : $t('Passport')" is-mandatory
          :disableModify="disableModify"
          :doc-type="primaryUserID" :doc-side="docSides.FRONT"
          :documents="guests[currentGuest].documents" :user-documents="selectedGuest.documents" :is-verified-status="isVerifiedStatus"
          @upload="uploadDocument($event, primaryUserID, docSides.FRONT)" />
          <div class="document-not-uploaded" v-if="!isAlreadyUploaded(primaryUserID, docSides.FRONT) && (isAlreadyUploaded(primaryUserID, docSides.BACK) || (!isInDocuments(primaryUserID, docSides.FRONT) && isInDocuments(primaryUserID, docSides.BACK)))">
            <FontAwesomeIcon :icon="['fa', 'exclamation-triangle']"/>
            {{$t('Warning: You have to upload front side')}}
          </div>
        </b-col>
        <b-col cols="12" v-if="hasFrontBackDocument">
          <RFUploadDocument :docName="isEuropean ? $t('ID Document') : $t('Passport')" is-mandatory
          :disableModify="disableModify"
          :doc-type="primaryUserID" :doc-side="docSides.BACK"
          :documents="guests[currentGuest].documents" :user-documents="selectedGuest.documents" :is-verified-status="isVerifiedStatus"
          @upload="uploadDocument($event, primaryUserID, docSides.BACK)"/>
          <div class="document-not-uploaded" v-if="!isAlreadyUploaded(primaryUserID, docSides.BACK) && (isAlreadyUploaded(primaryUserID, docSides.FRONT) || (!isInDocuments(primaryUserID, docSides.BACK) && isInDocuments(primaryUserID, docSides.FRONT)))">
            <FontAwesomeIcon :icon="['fa', 'exclamation-triangle']"/>
            {{$t('Warning: You have to upload back side')}}
          </div>
        </b-col>
        <b-col cols="12" v-if="!hasFrontBackDocument">
          <RFUploadDocument :docName="isEuropean ? $t('ID Document') : $t('Passport')" is-mandatory
          :disableModify="disableModify"
          :doc-type="primaryUserID" :doc-side="docSides.FULL"
          :documents="guests[currentGuest].documents" :user-documents="selectedGuest.documents" :is-verified-status="isVerifiedStatus"
          @upload="uploadDocument($event, primaryUserID, docSides.FULL)" />
        </b-col>
      </b-row>
      <b-row class="upload-row">
        <b-col>
          <span class="upload-label">
            {{$t('University letter')}}
            <p class="sub-paragraph">{{$t('Acceptance letter issued by the University which shows the starting and end-date of your studies')}}</p>
          </span>
        </b-col>
        <b-col cols="12">
          <RFUploadDocument docName="University Letter"
          :disableModify="disableModify"
          :doc-type="docTypes.UNIVERSITY" :doc-side="docSides.FULL"
          :documents="guests[currentGuest].documents" :user-documents="selectedGuest.documents" :is-verified-status="isVerifiedStatus"
          @upload="uploadDocument($event, docTypes.UNIVERSITY, docSides.FULL)" />
        </b-col>
      </b-row>
      <b-row class="upload-row no-border">
        <b-col>
          <span class="upload-label">
            {{$t('Fiscal Code')}}
            <p class="sub-paragraph">{{$t('Alphanumeric code issued by the Italian Government (Agenzia delle Entrate) for your identification. It’s only necessary to register your contract. If you don’t have it, we can do it for you. Or add it later')}}</p>
          </span>
        </b-col>
        <b-col cols="12" class="my-2" v-if="!isVerifiedStatus">
          <RFCheckbox color="#fff" :label="$t('Does your document have a front and back page (upload separately)?')" v-model="hasFrontBackFiscalCode" :readonly="isVerifiedStatus"/>
        </b-col>
        <b-col cols="12" v-if="hasFrontBackFiscalCode">
          <RFUploadDocument :docName="$t('Fiscal Code')"
          :disableModify="disableModify"
          :doc-type="docTypes.FISCALCODE" :doc-side="docSides.FRONT"
          :documents="guests[currentGuest].documents" :user-documents="selectedGuest.documents" :is-verified-status="isVerifiedStatus"
          @upload="uploadDocument($event, docTypes.FISCALCODE, docSides.FRONT)"/>
          <div class="document-not-uploaded" v-if="!isAlreadyUploaded(docTypes.FISCALCODE, docSides.FRONT) && (isAlreadyUploaded(docTypes.FISCALCODE, docSides.BACK) || (!isInDocuments(docTypes.FISCALCODE, docSides.FRONT) && isInDocuments(docTypes.FISCALCODE, docSides.BACK)))">
            <FontAwesomeIcon :icon="['fa', 'exclamation-triangle']"/>
            {{$t('Warning: You have to upload front side')}}
          </div>
        </b-col>
        <b-col cols="12" v-if="hasFrontBackFiscalCode">
          <RFUploadDocument :docName="$t('Fiscal Code')"
          :disableModify="disableModify"
          :doc-type="docTypes.FISCALCODE" :doc-side="docSides.BACK"
          :documents="guests[currentGuest].documents" :user-documents="selectedGuest.documents" :is-verified-status="isVerifiedStatus"
          @upload="uploadDocument($event, docTypes.FISCALCODE, docSides.BACK)" />
          <div class="document-not-uploaded" v-if="!isAlreadyUploaded(docTypes.FISCALCODE, docSides.BACK) && (isAlreadyUploaded(docTypes.FISCALCODE, docSides.FRONT) || (!isInDocuments(docTypes.FISCALCODE, docSides.BACK) && isInDocuments(docTypes.FISCALCODE, docSides.FRONT)))">
            <FontAwesomeIcon :icon="['fa', 'exclamation-triangle']"/>
            {{$t('Warning: You have to upload back side')}}
          </div>
        </b-col>
        <b-col cols="12" v-if="!hasFrontBackFiscalCode">
          <RFUploadDocument :docName="$t('Fiscal Code')"
          :disableModify="disableModify"
          :doc-type="docTypes.FISCALCODE" :doc-side="docSides.FULL"
          :documents="guests[currentGuest].documents" :user-documents="selectedGuest.documents" :is-verified-status="isVerifiedStatus"
          @upload="uploadDocument($event, docTypes.FISCALCODE, docSides.FULL)"/>
        </b-col>
      </b-row>
      <b-row class="justify-content-between align-items-center mt-4">
        <b-col col class="d-flex justify-content-end">
          <div class="d-inline contact-us rf-white-color ls-3">{{`${$t('If you have any doubts')},`}}</div>
          <a href="https://wa.me/393518897372" target="_blank" class="d-inline contact-us rf-dark-gray-color ml-1">
            {{$t('Contact Us')}}
            <FontAwesomeIcon :icon="['fab', 'whatsapp']" color="#74776D"/>
          </a>
        </b-col>
      </b-row>
    </form>
    <DocumentModal />
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFInput from '@/components/forms/RFInput'
import RFFile from '@/components/forms/RFFile'
import RFInputAutocomplete from '@/components/forms/RFInputAutocomplete'
import RFRadio from '@/components/forms/RFRadio'
import RFDatePicker from '@/components/forms/RFDatePicker'
import RFLoader from '@/components/forms/RFLoader'
import RFValidation from '@/components/forms/RFValidation'
import RFUploadDocument from '@/components/forms/RFUploadDocument'
import RFCheckbox from '@/components/forms/RFCheckbox'

import DocumentModal from '@/components/modals/DocumentModal'

import {mapState} from 'vuex'
import {docSides, docTypes, nationalityTypes, userStatus} from '@/enums'
import {documentService, userService} from '@/services'

export default {
  name: 'YourRoofGuests',
  components: {
    RFButton,
    RFInput,
    RFFile,
    RFInputAutocomplete,
    RFRadio,
    RFDatePicker,
    RFLoader,
    RFValidation,
    RFUploadDocument,
    RFCheckbox,
    DocumentModal,
  },
  data() {
    return {
      docSides,
      docTypes,
      nationalityTypes,

      reservation: null,
      selectedGuest: null,

      disableModify: false,

      guestInfos: null,

      guests: [],
      loading: false,
      currentGuest: 0,
      hasFrontBackDocument: false,
      hasFrontBackFiscalCode: false,
      fileSizeLimit: 100, //In MB
    }
  },
  computed: {
    ...mapState({
      user: state => state.userStore.user,
    }),
    reservationGuests() {
      return this.reservation.guests
    },
    isEuropean() {
      return this.selectedGuest.ueStatus === this.nationalityTypes.ITALIAN || this.selectedGuest.ueStatus === this.nationalityTypes.UE
    },
    isItalian() {
      return this.selectedGuest.nationality === 'Italian'
    },
    primaryUserID() {
      return this.isEuropean ? this.docTypes.ID_DOCUMENT : this.docTypes.PASSPORT
    },
    nationalities() {
      return this.$store.state.countryStore.countries.map(country => country.nationality)
    },
    countryCodes() {
      // return this.$store.state.countryStore.countries.map(country => country.name + ' - ' + country.dial_code)
      return this.$store.state.countryStore.countries.map(country => country.name)
    },
    countries() {
      return this.$store.state.countryStore.countries.map(country => country.name)
    },
    isVerifiedStatus() {
      return this.selectedGuest.status === userStatus.VERIFIED
    },
    isRegisteredStatus() {
      return this.selectedGuest.status === userStatus.REGISTERED
    }
  },
  beforeMount() {
    if (this.checkAllDocuments) {
      this.disableModify = true
    }
    const reservationIndex = this.user.reservations.findIndex(reservation => reservation.id === parseInt(this.$route.params.id))
    this.reservation = this.user.reservations[reservationIndex]
    this.selectGuest(this.currentGuest)
    this.reservationGuests.forEach(guest => this.guests.push({ id: guest.id, documents: [] })) // init guests to manipulate
  },
  watch: {
    hasFrontBackDocument: {
      handler() {
        this.documents = this.selectedGuest.documents.filter(document => document.docType !== this.primaryUserID)
      }
    },
    hasFrontBackFiscalCode: {
      handler() {
        this.documents = this.selectedGuest.documents.filter(document => document.docType !== this.docTypes.FISCALCODE)
      }
    }
  },
  methods: {
    setGuestFields() {
      this.guestInfos = {...this.selectedGuest}
    },
    selectGuest(index) {
      this.currentGuest = index
      this.selectedGuest = this.reservationGuests[this.currentGuest]
      this.setGuestFields()
    },
    findGuestIndex() {
      return this.guests.findIndex(guest => this.selectedGuest.id === guest.id)
    },
    findDocumentsIndex(documents, docType, docSide) {
      return documents.findIndex(document => document.docType === docType && document.docSide === docSide)
    },
    isAlreadyUploaded(docType, docSide) {
      const index = this.findDocumentsIndex(this.selectedGuest.documents, docType, docSide)
      return index >= 0
    },
    isInDocuments(docType, docSide) {
      const index = this.findGuestIndex()

      if (index >= 0) {
        const documentIndex = this.findDocumentsIndex(this.guests[index].documents, docType, docSide)
        return documentIndex >= 0
      } else {
        return false
      }
    },
    async update() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true
        this.selectedGuest = await userService.updateGuest(this.reservation.id, this.reservation.user.id, this.guestInfos)
        this.$store.commit('userStore/updateGuest', { reservationId: this.reservation.id, updatedGuest: this.selectedGuest })
        this.$toasted.success(this.$i18n.t(`Guest data successfully updated!`))
        this.loading = false
      }
    },
    uploadDocument(document, docType, docSide) {
      this.$nextTick(() => {
        if (document.size <= this.fileSizeLimit * 1024 * 1024) {
          const index = this.findGuestIndex()
          this.guests[index].documents.push({
            document,
            docSide,
            docType,
          })
          this.sendDocument(docType, docSide)
        } else {
          this.$toasted.error(this.$i18n.t('File size too bigger. Try again with another smaller file.'))
        }
      })
    },
    async sendDocument(docType, docSide) {
      const index = this.findGuestIndex()

      if (index >= 0) {
        const formData = new FormData()

        const documentIndex = this.findDocumentsIndex(this.guests[index].documents, docType, docSide)
        const document = this.guests[index].documents[documentIndex]

        formData.append('file', document.document)
        formData.append('docSide', document.docSide)
        formData.append('docType', document.docType)

        const { data: updatedGuest, status } = await documentService.sendGuestDocument(formData, this.reservation.id, this.selectedGuest.id)

        if (status === 200) {
          this.$nextTick(() => {
            this.selectedGuest = { ...updatedGuest }
            this.guestInfos = { ...updatedGuest }
            this.$store.commit('userStore/updateGuest', { reservationId: this.reservation.id, updatedGuest })

            const guestIndex = this.findGuestIndex()
            const documentIndex = this.findDocumentsIndex(this.guests[guestIndex].documents, docType, docSide)
            this.guests[guestIndex].documents.splice(documentIndex, 1)
          })
        }
      }
    },
    showDocument(docType, docSide) {
      const guestIndex = this.findGuestIndex();
      const index = this.guests[guestIndex].documents.findIndex(document => document.docType === docType && document.docSide === docSide)
      const document = this.guests[guestIndex].documents[index]

      this.$store.commit('documentStore/setDocument', document)
      this.$root.$emit('bv::show::modal', 'document-modal')
    },
    removeDocument(docType, docSide) {
      const guestIndex = this.findGuestIndex()
      const index = this.findDocumentsIndex(this.guests[guestIndex].documents, docType, docSide)
      this.guests[guestIndex].documents.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.your-roof-guests {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;

  .title {
    @include font-style($montserrat, 'medium', $font-20);
    color: $dark-gray;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: .5px solid $dark-gray;
  }
  .guests-header {
    display: flex;

    .guest-icon {
      margin-right: 15px;
      cursor: pointer;
      color: $gray;

      &.selected {
        color: $dark-blue;
      }
    }
  }
  .guest-info {
    margin: 50px 0;

    .your-info-subtitle {
      @include font-style($montserrat, 'medium', $font-18);
      text-transform: uppercase;
      margin: 30px 0 20px 0;
      color: $dark-blue;
    }
  }
  .complete-profile,
  .guest-documents {
    position: relative;
    background-color: $dark-blue;
    padding: 40px 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%;
      background-color: $dark-blue;
      z-index: -1;
    }
    &:after {
      left: 100%;
    }
    &:before {
      left: -100%;
    }
    .documents-title {
      @include font-style($montserrat, 'semibold', $font-18);
      color: $white;
      text-transform: uppercase;
    }
    .upload-row {
      padding: 15px 0;
      border-bottom: .5px solid $gray;

      &.no-border {
        border: none;
      }
      .upload-label {
        @include font-style($montserrat, 'medium', $font-14);
        color: $white;

        .sub-paragraph {
          font-size: $font-12;
        }
      }
      .document-not-uploaded {
        @include font-style($montserrat, 'medium', $font-14);
        color: $warning;
        margin-top: 10px;
      }
      .upload-success {
        color: $success;
      }
      .show-document {
        color: $white;
        margin: 0 10px;
      }
      .remove-document {
        color: $danger;
        margin-right: 10px;
      }
    }
  }
  .complete-profile {
    @include font-style($montserrat, 'semibold', $font-18);
    color: $white;
    text-transform: uppercase;
    padding: 50px 0;
  }
  .contact-us {
    @include font-style($montserrat, 'medium', $font-10);
    text-transform: uppercase;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .your-roof-guests {
    height: 100%;

    .title {
      margin-left: 50px;
    }
    .guests-header {
      margin-top: 0;
    }
    .guests-header,
    .guest-info {
      padding-left: 50px;
    }
    .complete-profile,
    .guest-documents {
      height: 100%;
      padding: 40px 0 40px 50px;

      &:before {
        display: none;
      }
    }
    .complete-profile {
      padding: 50px;
    }
  }
}
</style>
